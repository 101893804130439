import { I_Error } from "../interfaces"
import { ErrorType, TypeRule } from "../enums"

interface I_RequiredRule {
  required: boolean
  message: string
}

interface I_TypeRule {
  type: TypeRule
  message: string
}

export const requiredRule = (errors: I_Error[]): I_RequiredRule => {
  const required = true

  const error = errors.find(({ type }) => type === ErrorType.Required)
  const message = error ? error.message : ""

  return { required, message }
}

export const typeRule = (errors: I_Error[], type: TypeRule): I_TypeRule => {
  const error = errors.find(({ type }) => type === ErrorType.Email)
  const message = error ? error.message : ""

  return { type, message }
}

export const nameValidator = (value: any, errors: I_Error[]): any => {
  const error = errors.find(({ type }) => type === ErrorType.Name)
  if (value.length < 3) {
    return Promise.reject(new Error(error?.message))
  }

  return Promise.resolve()
}

export const isbnValidator = (value: any, errors: I_Error[]): any => {
  const error = errors.find(({ type }) => type === ErrorType.ISBN)
  const isbns = value.split(/[ ,.\n]+/)

  const invalidIsbns = isbns.filter((isbn: string) => {
    const isbnFormatted = isbn.replace(/-/g, "")

    if (isbnFormatted.trim() === "") {
      return false
    }

    if (!/^\d+$/.test(isbnFormatted)) {
      return true
    }

    return (
      isbnFormatted.length !== 10 &&
      isbnFormatted.length !== 13 &&
      isbnFormatted.length !== 0
    )
  })

  if (invalidIsbns.length > 0) {
    const message = `${error?.message} ${invalidIsbns.join(", ")}`
    return Promise.reject(new Error(message))
  }

  return Promise.resolve()
}
